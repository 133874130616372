import React from 'react';

const EmploymentMission = () => {
  return (
    <div className='news_section_2'>
      <div className='row'>
        <div className='col-md-6 align-self-center'>
          <div className='news_img'>
            <img src='images/mision/mision_1.jpg' />
          </div>
        </div>
        <div className='col-md-6'>
          <h1 className='give_taital'>
            ইত্তিহাদ ফাউন্ডেশনের স্বাবলম্বী করন প্রোজেক্ট
          </h1>
          <p className='ipsum_text'>
            আমরা "ইত্তিহাদ ফাউন্ডেশন " এর পক্ষ থেকে একজন অসচ্ছল মানুষের
            কর্মসংস্থান এর ব্যবস্থা করার উদ্যোগ নিয়েছি। একজন মানুষের কর্মসংস্থান
            মানে একটি পরিবারের মুখে হাসি। এক ভাই আবদার করে তার জন্য একটা
            কর্মসংস্থানের ব্যবস্থা করে দেওয়ার। তাই আমরা ইত্তিহাদ ফাউন্ডেশনের
            পক্ষ থেকে একটা অটোরিকশা নিয়ে দেওয়ার সিদ্ধান্ত নিলাম।
            <br />
            <br />
            আপনারা ও চাইলে আমাদের সাথে এই কাজের অংশীদার হতে পারেন। আপনারা এগিয়ে
            আসলে খুব সহজেই আমরা এই কাজে সফল হতে পারি ইন-শা-আল্লাহ্।
            <br />
            <br />
            যারা যারা অংশীদার হতে চেয়েছেন আল্লাহ আপনাদের উত্তম প্রতিদান
            দিন,আমীন। আর যারা আমাদের সাথে অংশীদার হতে চান তারা আমাদের সাথে
            যোগাযোগ করতে পারেন। অথবা বিকাশ করতে পারেন।
            <br />
            <br />
            বিকাশ-০১৭৪৩২৪৮৩৮৬ <br />
            নগদ-০১৭৪৩২৪৮৩৮৬ <br />
            রেফারেন্স লিখুন- employment <br />
            প্লিজ সকলেই সাধ্যমতো অংশগ্রহণ করুন, শেয়ার করে হলেও অবদান রাখুন।
          </p>
          <h5 className='raised_text'>
            Raised: ৳22,000
            <span className='goal_text'>Goal: ৳35,000</span>
          </h5>
          <div className='donate_btn_main'>
            {/* <div className='readmore_btn'>
              <a href='#'>Read More</a>
            </div> */}
            <div className='readmore_btn_1'>
              <a href='#'>Donate Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmploymentMission;
