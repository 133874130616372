import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './pages';
import About from './pages/about';
import ContactUs from './pages/contact-us';
import Donate from './pages/donate';
import Home from './pages/home';
import NextMission from './pages/next-missions';
import SuccessfulMission from './pages/successful-mission';

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Index />}>
          <Route index element={<Home />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/upcoming-mission' element={<NextMission />} />
          <Route path='/successful-missions' element={<SuccessfulMission />} />
          <Route path='/donate' element={<Donate />} />
          {/* <Route path='/contact-us' element={<ContactUs />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
